import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import {
  AppWrapperModule,
  DS_APP_WRAPPER_CONFIG,
  HEALTH_CHECK_OPTIONS_TOKEN,
  HealthCheckBannerOptions,
} from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { DsPrintModule } from '@design-system/feature/print';
import {
  DS_DISABLE_SPOTLIGHTS,
  DsSpotlightModule,
} from '@design-system/feature/spotlight';
import { UserService } from '@features/auth';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import {
  APPLICATION_INSIGHTS_KEY,
  APPLICATION_INSIGHTS_MIN_LOG_LEVEL,
} from '@shared-lib/app-insights';
import { environment } from '../environments/environment';
import { AddUserGuard } from './add-user/add-user.guard';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core.module';
import { HelpIframeComponent } from './help/help-iframe/help-iframe.component';
import { BASE_PATH } from './shared/generated';
import { SharedModule } from './shared/shared.module';
import { SpotlightResetComponent } from './spotlight-reset/spotlight-reset.component';
@NgModule({
  declarations: [AppComponent, HelpIframeComponent, SpotlightResetComponent],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    SharedLibFeatTranslationModule,
    DsPageModule,
    CoreModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        deps: [UserService],
        useFactory: () => ({
          appName: 'BPM',
          productScreenBasePath: environment.LINKS.PRODUCT_SCREEN,
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          appInsightsRole: 'FE: BPM',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appHelpPath: 'help',
          appNewsPath: 'release-notes',
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
      {
        provide: APPLICATION_INSIGHTS_MIN_LOG_LEVEL,
        useFactory: () => +environment.application_min_log_level,
      },
    ]),
    DsBreadcrumbsModule,
    DsPrintModule,
    DsSpotlightModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
    // HelpPagesModule,
    // ReleasNotesModule,
  ],
  providers: [
    {
      provide: BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    // SPOTLIGHTS flag
    {
      provide: DS_DISABLE_SPOTLIGHTS,
      useFactory: () => environment.disable_spotlights,
    },
    // HEALTHCHECK STATUS BANNER
    {
      provide: HEALTH_CHECK_OPTIONS_TOKEN,
      useFactory: (): HealthCheckBannerOptions => ({
        productsToCheck: ['Business Partner Management'],
        statusPaldeskApiUri:
          environment.palfinger_app_gateway + '/status/api/v1/health',
        statusPaldeskPageUri: environment.LINKS.STATUS_PALDESK_PAGE,
      }),
    },
    AddUserGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
