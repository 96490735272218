<ds-spotlight id="spotlight-welcome-message">
  <h3>{{ 'bpm.spotlight.welcome' | translate }}</h3>
  {{ 'bpm.spotlight.welcome_subtext' | translate }}
</ds-spotlight>
<ds-app-wrapper [menu]="menu">
  <ds-breadcrumbs baseUrl="/" baseLabel="{{ 'bpm.apps.bpm' }}">
  </ds-breadcrumbs>
  <div fxFlex="1 1 auto" class="flex-page-container">
    <router-outlet></router-outlet>
  </div>
</ds-app-wrapper>
<div class="helper-spotlight-helper" [spotlight]="helperSpotlight"></div>
<ds-spotlight position="below" id="bpm-spotlight-helper" #helperSpotlight>
  {{ 'bpm.spotlight.helper' | translate }}
</ds-spotlight>
