import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PARTNERTYPES } from '@config';
import { UserService } from '@features/auth';
import { environment } from '../environments/environment';
import { APPS } from './shared/app-roles';
import { Menu } from '@design-system/feature/app-wrapper-v2';

@Component({
  selector: 'bpm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  paldeskUrl: string;

  constructor(
    private userService: UserService,
    private destroyRef: DestroyRef,
  ) {
    this.paldeskUrl = environment.LINKS.PALDESK_BASEPATH;
  }

  menu: Menu;

  ngOnInit() {
    this.userService.currentUser
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.menu = {
          title: 'BPM',
          titleIcon: 'how_to_reg',
          navigation: [],
        };

        this.assembleSidebar();
      });
  }

  assembleSidebar() {
    if (
      this.userService.hasOneRole([
        APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.ADMIN,
        APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.NETWORK_COMPANY_MANAGEMENT,
        APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.NETWORK_USER_MANAGEMENT,
        APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.SUPPLIER_MANAGEMENT,
        APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.PARTNER_NETWORK_READ_ONLY,
      ])
    ) {
      this.menu.navigation.push({
        title: 'bpm.apps.create_partner',
        icon: 'business_center',
        routerLink: '/palfinger-partner',
      });
    }
    if (!this.userService.isOneOfPartnerTypes([PARTNERTYPES.END_CUSTOMER])) {
      this.menu.navigation.push({
        title: 'bpm.apps.company_management',
        icon: 'people',
        routerLink: '/company-management',
      });
    }
    if (
      this.userService.hasRole(APPS.USER_MANAGEMENT.ROLES.USER) &&
      this.userService.isPartnerType(PARTNERTYPES.END_CUSTOMER)
    ) {
      this.menu.navigation.push({
        title: 'bpm.apps.user_management',
        icon: 'people',
        routerLink: '/user-management',
      });
    }
    if (
      this.userService.hasRole(
        APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.PALSOFT_USER,
      )
    ) {
      this.menu.navigation.push({
        title: 'bpm.apps.palsoft',
        icon: 'lock',
        routerLink: '/palsoft',
      });
    }
    if (
      this.userService.hasRole(APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.ADMIN)
    ) {
      this.menu.navigation.push({
        title: 'bpm.apps.content_management',
        icon: 'flag',
        routerLink: '/content-management',
        children: [
          {
            title: 'bpm.content_management.product_lines',
            routerLink: '/content-management/product-lines',
          },
          {
            title: 'bpm.content_management.roles_and_applications',
            routerLink: '/content-management/roles-and-applications',
          },
        ],
      });
    }
  }
}
